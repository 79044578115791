<template>
  <div class="cover-container">
    <img :src="getURL()" alt="">
    <div class="cover-button-block"  :class="{'cover-button-block-anima-show':isShowButton}" v-if="(cover || url ) && !isLive">
      <div></div>
      <div><i></i><span></span></div>
    </div>
    <div v-if="isLive" class="cover-tip-block global_container_center">
      {{getContent()}}
    </div>
  </div>
</template>
<script>
export default {
  name:"cover",
  props:{
    chatList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    phase:{
      type:Number,
      default:0
    },
    isShowButton:{
      type:Boolean,
      default:false
    },
    url:{
      type:String,
      default:""
    },
    isLive:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      cover:""
    }
  },
  methods:{
    getContent(){
      if(this.phase < 2){
        let chatList = this.chatList.filter(chat=>chat.content_type==1);
        if(chatList.length < 1) return "专家正在准备会议，请耐心等待 "
        return "专家正在发言，请耐心等待"
      }else{
        return this.phase < 4 ? "专家正在回答问题,请耐心等待" : "会议已结束"
      }
    },
    setURL(url){
      this.cover = url
    },
    getURL(){
      let defaultURL = this.isLive ? require('@/assets/images/live/doctor_cover_bg.png') : require('@/assets/images/live/doctor_cover.png')
      return this.cover ||　this.url ||　defaultURL
    }
  }
}
</script>
<style>
.cover-container{
  width:100%;
  height:100%;
  position: absolute;
  left:0;
  top:0;
  z-index:500;
}
.cover-container img{
  width:100%;
  height:100%;
}


.cover-button-block {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 521;
  /* display: flex;
        align-items: center;
        justify-content: center;; */
}
.cover-button-block > div {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cover-button-block:first-child {
  position: absolute;
}
.cover-button-block > div:first-child {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.cover-button-block-anima-show  > div:first-child{
  animation: playButton 0.5s ease-in-out infinite alternate;
  -webkit-animation: playButton 0.5s ease-in-out infinite alternate;
}
@keyframes playButton {
  0% {
    transform: scale(1, 1);
    box-shadow: 0 0px 1px rgba(129, 48, 75, 1);
  }
  100% {
    transform: scale(1.01, 1.01);
    box-shadow: 0 0px 20px rgba(129, 48, 75, 1);
  }
}
@-webkit-keyframes playButton {
  0% {
    transform: scale(1, 1);
    box-shadow: 0 0px 1px rgba(129, 48, 75, 1);
  }
  100% {
    transform: scale(1.01, 1.01);
    box-shadow: 0 0px 20px rgba(129, 48, 75, 1);
  }
}
.cover-button-block:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-button-block:last-child > div {
  width: 15.33vw;
  height: 15.33vw;
  border-radius: 50%;
  /* border: .4vw solid #fff; */
}
.cover-button-block:last-child > div > i {
  display: inline-block;
  width: 100%;
  height: 100%;
  /* background: #999; */
  /* background: #9999998f; */
  border:1px solid #999;
  border-radius: 50%;
  position: relative;
  z-index: -1;
  opacity: 0.9;
}
.cover-button-block:last-child > div > span {
  display: inline-block;
  width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: rgb(210, 210, 210);
  /* border-left-color: rgb(106, 106, 106); */
  position: absolute;
  height: 0;
  border-style: solid;
  border-left-width: 5vw;
  top: 4.83vw;
  left: 6.33vw;
  border-right-width: 5vw;
  border-top-width: 3.33vw;
  border-bottom-width: 3.33vw;
}
.cover-tip-block{
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 521;
  color:#fff;
}
</style>